
import { ref, reactive } from 'vue';




export function useTemplateBase(template, elementId = 'container', options = {}) {



  let _state = reactive({
    stage: null,
    layer_elements: null,
    groupClipped: null,
    groupFrame: null,
    groupViews: null,
  });

  let selectedElementIndex = ref(null)


  init()
  function init() {


    if (_state.stage) {
      // return
      //    _state.stage.removeChildren();
    } else {

      _state.stage = new Konva.Stage({
        container: `#${elementId}`, //'#container',
        width: template.width, //1080,
        height: template.height, //1080,
      })
    }

    selectedElementIndex.value = null

    _state.layer_elements = new Konva.Layer()
    _state.groupClipped = new Konva.Group()
    _state.groupFrame = new Konva.Group()
    _state.groupViews = new Konva.Group()


    // create stage

    // add layers to stage
    _state.stage.add(_state.layer_elements);

    // add group to grid layer
    _state.groupFrame.add(_state.groupClipped)
    _state.layer_elements.add(_state.groupFrame)
    _state.layer_elements.add(_state.groupViews)

    setWidthByElementId()
    // if (options.width) {
    //   setWidth(options.width)
    // }
  }

  function clear() {
    _state.layer_elements.removeChildren()
    _state.groupClipped.removeChildren()
    _state.groupFrame.removeChildren()
    _state.groupViews.removeChildren()

    selectedElementIndex.value = null
  }
  function getPhotoWishElements() {
    let elements = _state.layer_elements.children.filter((node) => node.attrs.pw === true)
    return elements
  }

  function scaleStage(scale = 1) {
    // _state.stage.width(_state.stage.width() * scale);
    // _state.stage.height(_state.stage.height() * scale);
    // _state.stage.scale({ x: scale, y: scale });
  }



  function setWidthByElementId() {

    debugger
    //https://konvajs.org/docs/sandbox/Responsive_Canvas.html
    // check later

    // now we need to fit stage into parent container (object contain)
    let containerWidth = document.getElementById(elementId).clientWidth
    let containerHeight = document.getElementById(elementId).clientHeight


    // get full widow size
    // var containerWidth = window.innerWidth;
    // var containerHeight = window.innerHeight * 0.95;


    var sceneWidth = _state.stage.width();
    var sceneHeight = _state.stage.height();

    //calcuate fit scale
    var scale = Math.min(containerWidth / sceneWidth, containerHeight / sceneHeight);

    // set stage scale
    _state.stage.width(sceneWidth * scale);
    _state.stage.height(sceneHeight * scale);
    _state.stage.scale({ x: scale, y: scale });



  }



  return {

    init,
    ..._state,
    getPhotoWishElements,
    selectedElementIndex,
    clear,
    scaleStage

  }
}

