import { default as index4VSbj0QMN4Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/index.vue?macro=true";
import { default as _91eventId_93nS1iCsc8QQMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/[eventId].vue?macro=true";
import { default as indexjyapopBYAGMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/index.vue?macro=true";
import { default as previewZATqyhG8gfMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/preview.vue?macro=true";
import { default as previewImagefMUzcW1uxCMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/previewImage.vue?macro=true";
import { default as slidetWDicS2PFgMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/slide.vue?macro=true";
import { default as testJYNFKdJJ5WMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/test.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "present-eventId",
    path: "/present/:eventId()",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/[eventId].vue").then(m => m.default || m)
  },
  {
    name: "present",
    path: "/present",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/index.vue").then(m => m.default || m)
  },
  {
    name: "present-preview",
    path: "/present/preview",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/preview.vue").then(m => m.default || m)
  },
  {
    name: "present-previewImage",
    path: "/present/previewImage",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/previewImage.vue").then(m => m.default || m)
  },
  {
    name: "present-slide",
    path: "/present/slide",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/present/slide.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    meta: testJYNFKdJJ5WMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-present-v2/pages/test.vue").then(m => m.default || m)
  }
]