export function useTemplateUtils() {



  function fontAutoSize(textElement) {

    // get rect of text element

    let { x, y, width, height, autoSize, text } = textElement.getAttrs()

    if (!autoSize) {
      autoSize = [22, 48]
    }

    let [min, max] = autoSize;

    //do auto size
    let count = 0
    let _fontSize = min
    textElement.fontSize(min)

    let text_removed_all_spaces = text.replace(/ +/g, '');

    // text remove all newlines 
    let text_removed_all_newlines = text_removed_all_spaces.replace(/(\r\n|\n|\r)/gm, "");

    while (hasEmptySpace(textElement, text_removed_all_newlines, _fontSize + 1) && (_fontSize + 1) <= max && count < 40) {
      _fontSize++
      count++
    }
    textElement.fontSize(_fontSize)


    console.log(`${textElement.name()} autosize font at : `, _fontSize)

    function hasEmptySpace(container, text_removed_all_spaces, nextFontSize) {
      container.fontSize(nextFontSize)
  
      let sum = 0
      for (let index = 0; index < container.textArr.length; index++) {
          const element = container.textArr[index].text.replace(/ +/g, '');
  
          sum += element.length;
      }
  
      if (sum == text_removed_all_spaces.length) {
          return true
      } else {
          return false
      }
  
  
  }
  }

  function wrappedWishText(element: any, words: Array<string>) {
    let shape = new Konva.Shape({
      x: element.x() || 0,
      y: element.y() || 0,
      width: element.width,
      height: element.height,
      sceneFunc: function (context, shape) {
        let fontSize = element.fontSize() || 18;
        let lineHeight = element.lineHeight() || 1;
        let lineHeightPx = fontSize * lineHeight;
        let textColor = element.fill() || '#000';
        let horizontalAlign = element.align() || 'left'; // left, center, right
        let verticalAlign = element.verticalAlign() || 'top'; // top, middle, bottom
        let x = 0;
        let y = 0 + 10;
        let line = '';
        let lines = [];

        context.setAttr('font', fontSize + 'px ' + element.fontFamily());
        context.setAttr('textBaseline', 'top');
        context.setAttr('fillStyle', textColor);

        // Measure and wrap text
        for (let n = 0; n < words.length; n++) {
          let testLine = line + words[n];
          let metrics = context.measureText(testLine);
          let testWidth = metrics.width;

          if (testWidth > element.width() && n > 0) {
            lines.push(line.trim());
            line = words[n];
          } else {
            line = testLine;
          }
        }
        lines.push(line.trim());

        // Calculate vertical alignment offset
        let totalHeight = lines.length * lineHeightPx;

        if (totalHeight > element.height()) {
          totalHeight = element.height();
        }

        if (verticalAlign === 'middle') {
          y = ((element.height() - totalHeight) / 2) + 10;
        } else if (verticalAlign === 'bottom') {
          y = element.height() - totalHeight;
        }

        // Render lines with horizontal alignment
        for (let i = 0; i < lines.length; i++) {
          let lineWidth = context.measureText(lines[i]).width;
          if (horizontalAlign === 'center') {
            x = (element.width() - lineWidth) / 2;
          } else if (horizontalAlign === 'right') {
            x = element.width() - lineWidth;
          } else {
            x = 0;
          }
          context.fillText(lines[i], x, y);
          y += lineHeightPx;
          // Check if the next line would exceed the container height
          if (y + lineHeightPx > element.height()) {
            break;
          }
        }
        context.fillStrokeShape(shape);
      }
    });

    return shape;
  }



  function whenFontIsLoaded(stage) {
    //get all text elements

    //for each text element

    //if font is not loaded

    //wait for font to load

    //if font is loaded

  }

  function drawRectanglesOnElements() {

  }


  return {
    fontAutoSize,
    wrappedWishText,
    whenFontIsLoaded,
    drawRectanglesOnElements
  }

}

