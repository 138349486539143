import * as Sentry from '@sentry/vue';
import { useEventStore } from '~~/store/event';

export default defineNuxtPlugin(async (nuxtApp) => {

  let isLocal = window.location.protocol == 'http:'
  if (isLocal) {
    return
  }

  console.log('hello from sentry client')

  const { public: { sentry } } = useRuntimeConfig()

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    release: "photowish-present-v2@1.0.0",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  })


  let eventStore = useEventStore()
  watch(() => eventStore.eventId, (a, b) => {
    Sentry.setUser({
      eventId: eventStore.eventId,
    })
  })
})


function mockError() {
  return new Promise((resolve, reject) => {

    reject('mock error')
  })
}

