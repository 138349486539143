import { useTemplateStore } from './template';
import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'

import { doc, getDoc, updateDoc, increment, setDoc, onSnapshot } from 'firebase/firestore'


import { useEventStore } from './event'
import _shuffle from 'lodash/shuffle.js'
import _sample from 'lodash/sample.js'
import _forEach from 'lodash/forEach.js'
import _filter from 'lodash/filter.js'
import _difference from 'lodash/difference.js'
import _take from 'lodash/take.js'
import _tail from 'lodash/tail.js'
import _intersection from 'lodash/intersection.js'
import dayjs from 'dayjs';

export const usePreweddingStore = defineStore({
  id: 'prewedding',
  state: () => {
    return {

      //---
      all: [],
      played: [],
      fetched: [],
      lastFetchTimestamp: dayjs().valueOf(),

      queue : []
    }
  },
  actions: {
    async main() {
      
      this.shuffle(null)
      this.watchPreFetch()
      await this.fetchPrewedding()
    },
    shuffle (previousId) { 
      let arr  =  _shuffle (this.all)

      //ป้องกัน อันแรก ซำ้จากเดิม
      if (arr.length >=2 && arr[0] === previousId) {
        // Find another random index to swap with the first item
          const randomIndex = Math.floor(Math.random() * (arr.length - 1)) + 1;
          [arr[0], arr[randomIndex]] = [arr[randomIndex], arr[0]];
      }

      this.queue = arr
    },

    watchPreFetch() { 
      watch(() => this.queue, (newVal, oldVal) => {
        //take first 3 elements
        const take = _take(newVal, 2)
        //loop to prefetch
         
        _forEach(take, (url) => {
          this.preFetch(url)
        } )
      }, { deep: true })

    },


    async fetchPrewedding() {


      const p = new Promise(async (resolve) => {
        const eventStore = useEventStore()

        const eventId = eventStore.eventId

        const docRef = doc(db, `events/${eventId}/data/photos`)
        const response = await getDoc(docRef)
        //  
        if (!response.exists()) {
          resolve(false)
          return
        }

        const data = response.data()
         
        // object to values
        const preweddingList = Object.values(data)
         
        const list = preweddingList.map((l) => l.original.replace('original', 'present'));
        this.all = list.sort((a, b) => a - b)
        this.queue =   _shuffle (this.all)
        resolve(true)
      })

      return p
    },


    preFetch(url) { 
      //prevent duplicate
      if (this.fetched.includes(url)) {
        return
      }

      let img = new Image();
      img.onload = ()=> this.fetched.push(url)
      img.crossOrigin = "Anonymous"
      img.src = url
    }


  },
  getters: {},
})