

export function useTemplateWish({
  stage,
  groupFrame,
  groupClipped,
  layer_elements,
  wishData,
  template
}) {

  let guestImage = ref(null)
  let clonedGuestImageRef = ref(null)
  let frameAreaImage = ref(null)
  let savedAttrs = ref(null)
  let lastGoodPosition = ref(null)
  let imageLoaded = ref(false)

  const { fontAutoSize, wrappedWishText } = useTemplateUtils()

  function applyWishText() {
    if (wishData.wish && wishData.wish.text) {
      const textWish = stage.findOne('#wish')

      // fill text and scale font size
      textWish.text(wishData.wish.text)
      fontAutoSize(textWish)
      textWish.text('')

      // create wrapped texts
      const wrapped = wrappedWishText(textWish, wishData.wish?.words)
      layer_elements.add(wrapped)
    }

    if (wishData.guest && wishData.guest.name) {
      const textName = stage.findOne('#name')
      textName.text(wishData.guest.name)
      // fontAutoSize(textName)
    }
  }

  function insertGuestImage() {

    let accountHash = 'G-5q2BMDs1WUCh3jAuvl7w'

    let _guestImageHtml = new Image()
    _guestImageHtml.crossOrigin = "Anonymous"
    // _guestImageHtml.src = template?.removebg ?  wishData.guest.image.remove_bg :  wishData.guest.image.original
    // _guestImageHtml.src = template?.removebg ?  wishData.guest.image.remove_bg.replace('original', 'present') :  wishData.guest.image.original.replace('original', 'present')

    // ---- using cloudflare image id -----
    const imageId =  template?.removebg ? wishData.guest.image.cfRemovebgId : wishData.guest.image.cfId
    _guestImageHtml.src = `https://imagedelivery.net/${accountHash}/${imageId}/present`

    _guestImageHtml.onload = () => {

      imageLoaded.value = true
    }

    let guestImageObj = new Konva.Image({
      image: _guestImageHtml,
      id:'guest'
    })

    groupClipped.add(guestImageObj)

    guestImage.value = guestImageObj


  }

  function centerToFrameArea() {

    let frameArea = groupFrame.children.find((el) => el.attrs.id === 'FrameArea')
    frameAreaImage.value = frameArea
    let _guestImage = guestImage.value

    let frameAreaWidth = frameArea.width() * frameArea.scaleX()
    let frameAreaHeight = frameArea.height() * frameArea.scaleY()
    let imageWidth = _guestImage.width() * _guestImage.scaleX()
    let imageHeight = _guestImage.height() * _guestImage.scaleY()

    let frameCenterX = frameArea.x() + frameAreaWidth / 2
    let frameCenterY = frameArea.y() + frameAreaHeight / 2

    let scaleWidth = frameAreaWidth / imageWidth
    let scaleHeight = frameAreaHeight / imageHeight
    let scale = Math.max(scaleWidth, scaleHeight)

    _guestImage.scale({
      x: scale,
      y: scale
    })

    _guestImage.position({
      x: frameCenterX - imageWidth * scale / 2,
      y: frameCenterY - imageHeight * scale / 2
    })

    let rotation = frameArea.rotation()
    _guestImage.rotation(rotation)

    //if portrait move to top of frameArea
    if (imageWidth * 1.05 < imageHeight) {
      _guestImage.y(frameArea.y() )
      // _guestImage.y(frameArea.y() - imageHeight / 10)
    }

  }

  function setupTransform() {



    // clone mirror move
    let clonedGuestImage = guestImage.value.clone()
    clonedGuestImage.id('clonedGuestImage') // --- new

    layer_elements.add(clonedGuestImage)
    clonedGuestImageRef.value = clonedGuestImage

    // add transformer
    let tr = new Konva.Transformer({
      nodes: [guestImage.value, clonedGuestImage],
      keepRatio: true,
      rotateEnabled: false,
      // centeredScaling: true,
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      boundBoxFunc: function (oldBox, newBox) {

        let offset = 1
        let frameClient = frameAreaImage.value.getClientRect()
        // the newBox must be outside the frameArea
        if (newBox.x - offset > frameClient.x) {
          newBox.x = frameClient.x;
          console.log('newBox.x > frameAreaImage.value.x()')
          return oldBox;
        }

        if (newBox.y - offset > frameClient.y) {
          newBox.y = frameClient.y;
          console.log('newBox.y > frameAreaImage.value.y()')
          return oldBox;
        }

        //
        if (newBox.x + newBox.width + offset < frameClient.x + frameClient.width) {

          console.log('newBox.x + newBox.width < frameAreaImage.value.x() + frameAreaImage.value.width()')
          return oldBox;
        }

        if (newBox.y + newBox.height + offset < frameClient.y + frameClient.height) {

          console.log('newBox.y + newBox.height < frameAreaImage.value.y() + frameAreaImage.value.height()')
          return oldBox;
        }

        return newBox;
      },

    });
    layer_elements.add(tr)


    // set draggable and opacity
    clonedGuestImage.opacity(0)

    clonedGuestImage.draggable(true)
    guestImage.value.draggable(true)
    frameAreaImage.value.draggable(false)


    // set events for clonedGuestImage
    clonedGuestImage.on('dragstart', function () {
      clonedGuestImage.opacity(0.25)
      lastGoodPosition.value = clonedGuestImage.position()
    })


    clonedGuestImage.on('dragmove', function () {
      let offset = 1
      let frameClient = frameAreaImage.value.getClientRect()
      let clonedGuestClient = clonedGuestImage.getClientRect()

      if (clonedGuestClient.x - offset > frameClient.x) {
        clonedGuestImage.x(frameAreaImage.value.x())
        guestImage.value.x(frameAreaImage.value.x())
      }

      if (clonedGuestClient.y - offset > frameClient.y) {
        clonedGuestImage.y(frameAreaImage.value.y())
        guestImage.value.y(frameAreaImage.value.y())
      }



      if (clonedGuestClient.x + clonedGuestClient.width < frameClient.x + frameClient.width - offset) {
        clonedGuestImage.x(lastGoodPosition.value.x)
        guestImage.value.x(lastGoodPosition.value.x)
      }

      if (clonedGuestClient.y + clonedGuestClient.height + offset < frameClient.y + frameClient.height) {
        clonedGuestImage.y(lastGoodPosition.value.y)
        guestImage.value.y(lastGoodPosition.value.y)
      }
      lastGoodPosition.value = clonedGuestImage.position()


    })

    clonedGuestImage.on('dragend', function () {
      clonedGuestImage.opacity(0)
    })

    clonedGuestImage.on('transform', function () {
      clonedGuestImage.opacity(0.25)
    })

    clonedGuestImage.on('transformend', function () {
      clonedGuestImage.opacity(0)
    })


    // --- new ---
    guestImage.value.setAttrs({
      width: clonedGuestImage.width(),
      height: clonedGuestImage.height(),
    })





    //for debug
    // drawPoints()
  }

  function setupTransformRemoveBg() {
    // clone mirror move
    let clonedGuestImage = guestImage.value.clone()
    clonedGuestImage.id('clonedGuestImage') // -- new

    layer_elements.add(clonedGuestImage)
    clonedGuestImageRef.value = clonedGuestImage

    // add transformer
    let tr = new Konva.Transformer({
      nodes: [guestImage.value, clonedGuestImage],
      keepRatio: true,
      rotateEnabled: false,
      // centeredScaling: true,
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],


    });
    layer_elements.add(tr)


    // set draggable and opacity
    clonedGuestImage.opacity(0)

    clonedGuestImage.draggable(true)
    guestImage.value.draggable(true)
    frameAreaImage.value.draggable(false)


    // --- new ---
    guestImage.value.setAttrs({
      width: clonedGuestImage.width(),
      height: clonedGuestImage.height(),
    })


  }


  function resetImagePosition() {

  }


  function drawPoints() {

    createPointWithLabel(layer_elements, frameAreaImage.value.x(), frameAreaImage.value.y(), 'topLeft')
    createPointWithLabel(layer_elements, frameAreaImage.value.x() + frameAreaImage.value.width(), frameAreaImage.value.y(), 'topRight')
    createPointWithLabel(layer_elements, frameAreaImage.value.x(), frameAreaImage.value.y() + frameAreaImage.value.height(), 'bottomLeft')
    createPointWithLabel(layer_elements, frameAreaImage.value.x() + frameAreaImage.value.width(), frameAreaImage.value.y() + frameAreaImage.value.height(), 'bottomRight')



  }

  function renderAutoSize() { }
  function rotateGroupAroundCenter(group, angle) {


    group.rotate(0);
    var bbox = group.getClientRect();
    var centerX = bbox.x + bbox.width / 2;
    var centerY = bbox.y + bbox.height / 2;
    var oldPos = {
      x: group.x(),
      y: group.y()
    };

    group.rotate(angle);
    var newBox = group.getClientRect();
    // _newbox = newBox
    var newCenterX = newBox.x + newBox.width / 2;
    var newCenterY = newBox.y + newBox.height / 2;

    var dx = centerX - newCenterX;
    var dy = centerY - newCenterY;

    // delta = {
    //     x: dx,
    //     y: dy
    // // }
    // group.x(oldPos.x + dx);
    // group.y(oldPos.y + dy);

    return {
      x: dx,
      y: dy
    }
  }

  function getGuestImageAttrs() {
    return guestImage.value.getAttrs()
  }

  //this will be call agaign call after guestImage is loaded
  function setGuestImageAttrs(attrs) {
    if (attrs == null) {
      return
    }

    guestImage.value.setAttrs(attrs)
    clonedGuestImageRef.value && clonedGuestImageRef.value.setAttrs(attrs)

  }

  function init() {
    insertGuestImage()
  }

  function createPointWithLabel(layer, x, y, label) {
    // Create the point as a small circle
    var point = new Konva.Circle({
      x: x,
      y: y,
      radius: 2, // Small radius for the point
      fill: 'green', // Color of the point
    });

    // Create the label as text
    var text = new Konva.Text({
      x: x + 10, // Position the text slightly to the right of the point
      y: y - 5, // Align it with the center of the point vertically
      text: `( ${x.toFixed(0)}, ${y.toFixed(0)} ) ${label}`, // Text to display
      fontSize: 12,
      fontFamily: 'Arial',
      fill: 'black'
    });

    // Add both the point and the text to the layer
    layer.add(point);
    layer.add(text);
    layer.draw(); // Draw the layer to render the new point and text
  }


  function removebgTamplate() {

    groupClipped.clipFunc(null)
    let palceholder = groupClipped.children.find((el) => el.attrs.id === 'Placeholder')
    palceholder.visible(false)
  }


  function hidePlaceholder() {
    groupClipped.children.find((el) => el.attrs.id === 'Placeholder').visible(false)
  }

  function readMode(restoreAttrs) {
    imageLoaded.value = false
    insertGuestImage()
    watch(imageLoaded, (newVal, oldVal) => {
      if (newVal) {
        centerToFrameArea()
        setGuestImageAttrs(restoreAttrs)
        template?.removebg && removebgTamplate()
        applyWishText()
        // frameAreaImage.value.opacity(0)
        frameAreaImage.value.visible(false)
      }
    })
    hidePlaceholder()

  }

  function writeMode(restoreAttrs) {

    imageLoaded.value = false
    insertGuestImage()
    watch(imageLoaded, (newVal, oldVal) => {

      if (newVal) {
        centerToFrameArea()
        if (template?.removebg) {
          setupTransformRemoveBg()
          removebgTamplate()
        } else {
          setupTransform()
        }
        setGuestImageAttrs(restoreAttrs)
        applyWishText()
        // frameAreaImage.value.opacity(0)
        frameAreaImage.value.visible(false)

      }
    })
    hidePlaceholder()
  }

  return {
    init,
    getGuestImageAttrs,
    setGuestImageAttrs, //for restore

    readMode,
    writeMode,
  }
}


interface TextElementData {
  x: number,
  y: number,
  width: number,
  height: number,
  fontFamily: string,
  lineHeight: number,
  fontSize: number,
}
