// import Rollbar from 'rollbar';
// import { useEventStore } from '~~/store/event';

export default defineNuxtPlugin((nuxtApp) => {

    //check if http or local return
    // let isLocal = window.location.protocol == 'http:'
    // if (isLocal) {
    //     return
    // }
    return

//     setTimeout(() => {
        
//     const a = new window.rollbar({
        
//         accessToken: "2eef2ca912214ee5af13a2f36931b538",
//         captureUncaught: true,
//         captureUnhandledRejections: true,
//         payload: {
//             environment: 'production',
//         }
//     });
// }, 5000);

    // setTimeout(() => {
    //     let eventStore = useEventStore()
    //     rollbar.configure({
    //         payload: {
    //             person:
    //             {
    //                 id: eventStore.eventId,
    //                 username: `${eventStore.eventId}`,
    //             }
    //         }
    //     });
    // }, 5 * 1000);

    // nuxtApp.vueApp.config.errorHandler = (err, vm, info) => {
    //     rollbar.error(err, vm, info)
    // }
})