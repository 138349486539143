import { defineStore } from 'pinia'
import { db } from '../service/firebase_config'

import { doc, updateDoc, setDoc, deleteField } from 'firebase/firestore'

import { useEventStore } from './event'
import Swal from 'sweetalert2'
import qrcode from "qrcode";

export const useSettingControlStore = defineStore({
  id: 'settingControl',
  state: () => {
    return {
      showModal: false,
      showInfo: false,
      showTimer: true,
      showQrCode: false,

      qrCodeImage: null,

      qrCodeHeight: 150, //px
      wishHeight: 90,// vh
      preweddingHeight: 95, //vh

      timer: {},
    }
  },
  actions: {
    main() {
      const eventStore:any = useEventStore()
      const settings = eventStore.event?.settings

      const showData = settings?.show || { photos : 4, wish : 4 }
      const timerData = settings?.timer || { photos : 15, wish : 15 }

      this.timer = {
        wish: {
          limit: showData.wish,
          timer: timerData.wish,
        },
        prewedding: {
          limit: showData.photos,
          timer: timerData.photos,
        },
      }

      setTimeout(async () => {
        await this.generateQrcode()
        this.restore()
      }, 1000);
    },

    
    async settingSlideMode(mode: string) {
      const eventStore = useEventStore()
      const eventId: any = eventStore.eventId

      try {
        const docRef = doc(db, 'events', eventId);
        return await setDoc(docRef, {
          settings: {
            slideMode: mode
          }
        }, { merge: true });
      }
      catch (error) {
        console.log(error)
      }
    },
    async settingTimer(presetPayload) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      const docRef = doc(db, 'events', eventId);
      return await setDoc(docRef, {
        settings: {
          timer: presetPayload
        }
      }, { merge: true });
    },

    // ----------------

    async generateQrcode() {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId;
      const base64String = await qrcode.toDataURL(
        `https://liff.line.me/1654864455-LlODqmBj?eventId=${eventId}`
      );
      this.qrCodeImage = base64String;
    },

    restore() {
      const _showInfo = window.localStorage.getItem('showInfo')
      const _showQrCode = window.localStorage.getItem('showQrCode')
      const _showTimer = window.localStorage.getItem('showTimer')
      const _qrCodeHeight = window.localStorage.getItem('qrCodeHeight')
      const _preweddingHeight = window.localStorage.getItem('preweddingHeight')
      const _wishHeight = window.localStorage.getItem('wishHeight')

      if (_showInfo) {
        this.showInfo = _showInfo == 'true' ? true : false
      }

      if (_showTimer) {
        this.showTimer = _showTimer == 'true' ? true : false
      }
      if (_showQrCode) {
        this.showQrCode = _showQrCode == 'true' ? true : false
      }

      if (_qrCodeHeight) {
        this.qrCodeHeight = parseInt(_qrCodeHeight)
      }

      if (_preweddingHeight) {
        this.preweddingHeight = parseInt(_preweddingHeight)
      }

      if (_wishHeight) {
        this.wishHeight = parseInt(_wishHeight)
      }
    },

    resetSetting() {
      Swal.fire({
        title: 'Are you sure?',
        text: "คุณต้องการรีเซ็ทการตั้งค่าใช่หรือไม่",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'รีเซ็ท'
      }).then((result) => {
        if (result.isConfirmed) {
          window.localStorage.removeItem('qrCodeHeight')
          window.localStorage.removeItem('preweddingHeight')
          window.localStorage.removeItem('wishHeight')
          window.localStorage.removeItem('showInfo')
          window.localStorage.removeItem('showQrCode')
          window.localStorage.removeItem('showTimer')
          this.showModal = false

          Swal.fire({
            icon: 'success',
            title: 'Reset เรียบร้อยแล้ว',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    },

    // ----------------
    toggleModal() {
      this.showModal = !this.showModal
    },

    closeModal() {
      this.showModal = false
    },

    toggleShowData(type: string) {
      switch (type) {
        case 'info':
          this.showInfo = !this.showInfo
          window.localStorage.setItem('showInfo', this.showInfo)
          break;

        case 'qrCode':
          this.showQrCode = !this.showQrCode
          window.localStorage.setItem('showQrCode', this.showQrCode)
          break;

        case 'timer':
          this.showTimer = !this.showTimer
          window.localStorage.setItem('showTimer', this.showTimer)

          break;
      }
    },

    settingSize(type: string, size: number) {
      switch (type) {
        case 'qrCode':
          this.qrCodeHeight = size
          window.localStorage.setItem('qrCodeHeight', this.qrCodeHeight)
          break;

        case 'prewedding':
          this.preweddingHeight = size
          window.localStorage.setItem('preweddingHeight', this.preweddingHeight)
          break;

        case 'wish':
          this.wishHeight = size
          window.localStorage.setItem('wishHeight', this.wishHeight)
          break;
      }
    },
  },
  getters: {}
})