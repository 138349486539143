import _ from 'lodash'

export const useTemplate = (elementId, templateData, wishData, { setting }) => {

  const base = useTemplateBase(templateData, elementId);
  
  const render = useTemplateRender({
    ...base,
    template: templateData,
  });

  const { writeMode, readMode, getGuestImageAttrs, setGuestImageAttrs  } = useTemplateWish({
    ...base,
    template: templateData,
    wishData: wishData,
  });

  const loadingTemplate = useTemplateLoading({
    ...base,
    template: templateData,
  });

  function createStudio(editorMode = false) {
    render.init()
    loadingTemplate.block( { 
      bgColor : 'black',
      text : ' '
    })
    let imageAttrs = wishData?.guest?.imageAttrs || null
    imageAttrs = _.size(imageAttrs) == 0 ? null : imageAttrs
    if (editorMode) {
      writeMode(imageAttrs)
    } else {
      readMode(imageAttrs)
    }
    loadingTemplate.start()
  }

  return {
    stage: base.stage,
    scaleStage: base.scaleStage,
    createStudio,
    
    getGuestImageAttrs,
    setGuestImageAttrs,
  }
}