export default defineNuxtRouteMiddleware((to, from) => {
  
  //ไม่โชว์ query หน้า home เพื่อความสวยงาม
  const isNotHomePage = from.fullPath?.includes('/present/slide')
  if(!isNotHomePage) {
    return
  }

  if (Object.keys(from.query).length && !Object.keys(to.query).length) {
    return navigateTo({
      path: to.path,
      hash: to.hash,
      params: to.params,
      query: from.query,
      redirectCode: 302, // default
    });
  }
});