import { realtimeDatabase } from '~~/service/firebase_config'
import { useEventStore } from '~/store/event'
import { useSlideStore } from '~/store/slide'
import { version } from "~/package.json";
import { ref, push, set, serverTimestamp, onDisconnect } from "firebase/database";
import dayjs from 'dayjs'

import _random from 'lodash/random.js'
import _include from 'lodash/includes.js'

const realtimeMonitor = () => {

  const $route = useRoute()
  const eventStore = useEventStore()
  const slideStore = useSlideStore()

  let presentRef = null
  let startAtDayjs = null //dayjs()

  watch(() => eventStore.eventId, () => {
    setPresentRef()
  })

  watch(() => slideStore.preweddingInstance, () => {
    start()
  })

  watch(() => slideStore.wishInstance, () => {
    start()
  })


  function setPresentRef() {
    const eventId = eventStore.eventId
    presentRef = ref(realtimeDatabase, `live/${eventId}/present`)
    onDisconnect(presentRef).remove()
  }

  function start() {
    if (startAtDayjs == null) {
      startAtDayjs = dayjs()
    }
    const viewMode = slideStore.view

    // get runnging time HH:mm:ss
    const runningTime = dayjs().diff(startAtDayjs, 'seconds')
    const runningTimeHHmm = dayjs().startOf('day').add(runningTime, 'seconds').format('HH:mm:ss')

    set(presentRef, {
      presentMode: slideStore.playMode,   //  mix || wish
      viewMode: viewMode,   // prewedding || wish
      eventId: eventStore.eventId,
      lastUpdateServerTime: serverTimestamp(),
      lastUpdate: dayjs().format(),
      startAt: startAtDayjs.format(),
      data: viewMode == 'prewedding' ? slideStore.preweddingInstance : slideStore.wishInstance.docId, //  prewedding url | wishData
      nonce: _random(1, 1000),
      // running :  slideStore.limit,
      // runNumber : slideStore.index + 1,
      played_all_instances: slideStore.allInstancesPlayed,
      played_prewedding: slideStore.preweddingInstancesPlayed,
      played_wish: slideStore.wishInstancesPlayed,
      connected_for : runningTimeHHmm,
      version : version
    })
  }

  function stop() {
    set(presentRef, null)
  }
}

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(realtimeMonitor)
})

export {
  realtimeMonitor
}
