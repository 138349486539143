


import { ref, reactive } from 'vue';
import FontFaceObserver  from 'fontfaceobserver'

export function useTemplateRender({
  groupClipped,
  groupFrame,
  layer_elements,
  stage,
  template
}) {

  return {
    init
  }
  function init() {
   setBackGround()
    template.elements.forEach((element, idx) => {
      let konvaObj = null

      switch (element.type) {
        case 'RECTANGLE':
          konvaObj = drawRectangle(element, idx);
          break;
        case 'FRAME':
          konvaObj = drawFrameImage2(element, idx);
          clipGroupPath();
          break;
        case 'IMAGE':
          konvaObj = drawImage(element, idx);
          break;
        case 'TEXT':
          konvaObj = drawText(element, idx);
          break;

        default:
          return
          break;
      }



    })
  }



  function setBackGround() {

    let imageObj = new Image();
    imageObj.crossOrigin = "Anonymous"

    let url = template.background.url
    url = url?.replace('original', 'present')
    imageObj.src = url;

    let image = new Konva.Image({
      x: 0,
      y: 0,
      image: imageObj,
      fill: template.background.color || 'black',
      width: template.width || 500,
      height: template.height || 500,
      // draggable: false,
    });
    layer_elements.add(image);
  }

  function drawRectangle(element, idx) {

    const rect = new Konva.Rect({
      name: element.name,
      type: element.type,
      x: element.x,
      y: element.y,
      width: element.width,
      height: element.height,
      fill: element.fill,
      index: idx,
      zIndex: idx,
      ts: new Date().toISOString(),
      ...getItemsStateAttrs(element)
    });
    layer_elements.add(rect);

    return rect;
  }

  function drawFrameImage2(frameData, index) {


    // let instance = useFrameRender( layer_elements, frameData, groupClipped)
    // instance.renderSample()
    groupClipped.moveToTop();
    groupFrame.moveToTop();
    groupFrame.setAttrs({
      index: index
    })
    frameData.elements.forEach((item, i) => {
      let obj = null
      switch (item.type) {
        case 'Placeholder':
          let img = new Image();
          img.crossOrigin = 'Anonymous';
          img.src = item.url;

          // img.src = '/images/frame_builder/placeholder.png'

          obj = new Konva.Image({
            ...getItemsStateAttrs(item),
            image: img,
            index: i,
          });

          groupClipped.add(obj);
          groupFrame.add(groupClipped);
          break;

        case 'Image':

          let img2 = new Image();
          img2.crossOrigin = 'Anonymous';
          img2.src = item.url;

          obj = new Konva.Image({
            ...getItemsStateAttrs(item),
            image: img2,
            index: i,

          });
          groupFrame.add(obj);

          break;
        case 'Rect':
          obj = new Konva.Rect({
            ...getItemsStateAttrs(item),
            index: i,
            opacity: 0.25,
            fill: "blue",
            visible: false,
          });
          groupFrame.add(obj);
          break;
        case 'Circle':
          obj = new Konva.Circle({
            ...getItemsStateAttrs(item),
            fill: "blue",
            opacity: 0.25,
            index: i,
            visible: false,
          });
          groupFrame.add(obj);
          break;

        case 'Path':
          obj = new Konva.Path({
            ...getItemsStateAttrs(item),

            data: item.data,

            opacity: 0.25,
            index: i,
            fill: "blue",
            visible: false,
          });
          groupFrame.add(obj);
          break;
        default:
          break;
      }

    });
    layer_elements.add(groupFrame);
  }

  function drawImage(element, index) {
    let imageObj = new Image();
    imageObj.crossOrigin = 'Anonymous';
    imageObj.onload = () => {
    };
    imageObj.src = element.url // || element.imageURL;
    const image = new Konva.Image({
      name: element.name,
      type: element.type,


      image: imageObj,
      index: index,

      ...getItemsStateAttrs(element)

    });

    layer_elements.add(image);

    return image;
  }

  function drawText(element, index) {
    // loadGoogleFont(element.fontFamily)
    // setTimeout(async () => {
    //     await loadGoogleFont(element.fontFamily)
    // }, 500);

    const text = new Konva.Text({
      name: element.name,
      type: element.type,
      x: element.x,
      y: element.y,
      width: element.width,
      height: element.height,
      text: element.text,
      fontFamily: element.fontFamily,
      fontSize: element.fontSize,
      fill: element.fill,
      fontStyle: element.fontStyle,
      align: element.align,
      verticalAlign: element.verticalAlign,
      lineHeight: element.lineHeight,
      index: index,
      zIndex: index,
      ts: new Date().toISOString(),
      ...getItemsStateAttrs(element)
    });


    if (element.textDecoration) {
      text.textDecoration('underline')
    }

    if (element.letterSpacing) {
      text.letterSpacing(element.letterSpacing)
    }


    layer_elements.add(text);

    // setTimeout(() => {
    //   text.fontFamily(element.fontFamily)
    //   console.log('set text font family', element.fontFamily)
    // }, 1000);
  }

  function clipGroupPath() {

    groupClipped.clipFunc(function (ctx) {
      // ctx.rect(0, 0, 500, 500);

      var path = new Path2D();
      var children = groupFrame.getChildren();

      children.forEach(item => {
        let attrs = item.getAttrs()
        if (attrs.pw == undefined) {
          return
        }

        switch (item.getClassName()) {
          case 'Rect':
            var p2 = new Path2D();
            p2.rect(0, 0, attrs.width, attrs.height);
            path.addPath(p2, _makeDotMattrixTransform(item));
            break;
          case 'Circle':

            var p2 = new Path2D();
            p2.arc(0, 0, attrs.radius, 0, 2 * Math.PI);
            path.addPath(p2, _makeDotMattrixTransform(item));

            break;
          case 'Path':

            var p2 = new Path2D(item.attrs.data);
            path.addPath(p2, _makeDotMattrixTransform(item));

            break;
          default:
            break;
        }
      });


      return [path]

    });
  }

  function _makeDotMattrixTransform(item) {
    var transform = item.getAbsoluteTransform().getMatrix();
    let m = new DOMMatrix();
    m.a = transform[0] / stage.scaleX();
    m.b = transform[1] / stage.scaleX();
    m.c = transform[2] / stage.scaleX()
    m.d = transform[3] / stage.scaleX()
    m.e = transform[4] / stage.scaleX()
    m.f = transform[5] / stage.scaleX()
    return m
  }




  function  getItemsStateAttrs(nodeData) {
    let { draggable, visible, image, index, ...rest } = nodeData
    return {
      ...rest,
      pw: true
    }
  }


  async function loadGoogleFont(fontFamily) {
    const url = 'https://fonts.googleapis.com/css2?family=' + fontFamily// + '&display=swap'

    const encoded = encodeURI(url)

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = encoded
    document.head.appendChild(link)


    setTimeout(async () => {
      try {
        let fontFaceObserver = new FontFaceObserver(fontFamily)
        await fontFaceObserver.load(null, 3000)
      } catch (error) {
          console.log('Google font is not exist: ', fontFamily)
      }
    }, 100);
  }
}
