import { ref, reactive } from 'vue';
import FontFaceObserver from 'fontfaceobserver'
// import w2 from './worker.js'

export function useTemplateLoading({

  layer_elements,
  stage,
  template
}) {

  //next wait time. 10 secs
  let TIMER_LIST = [100, 500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000]

  let elements = ref([])

  return {
    block,
    unblock,

    start,
  }
  function block(options = {}) {
    let loading = new Konva.Rect({
      x: 0,
      y: 0,
      width: template.width,
      height: template.height,
      fill: options.bgColor || 'white',
      opacity: 1,
      draggable: false,
    });
    layer_elements.add(loading);
    // add text loading
    let loadingText = new Konva.Text({
      x: template.width / 2,
      y: template.height / 2,
      text: options.text || 'Loading...',
      // text: 'Loading...',
      fontSize: 80,

      fill: 'gray',
      draggable: false,
    });
    loadingText.offsetX(loadingText.width() / 2);
    loadingText.offsetY(loadingText.height() / 2);
    layer_elements.add(loadingText);

    elements.value.push(loading)
    elements.value.push(loadingText)
  }

  function unblock() {
    elements.value.forEach(element => {
      element.remove()
    })
    elements.value = []
  }

  function addPromise(promise) {
    promiseList.value.push(promise)
  }

  //check if image is loaded
  function imgLoaded(imgElement) {
    return imgElement.complete && imgElement.naturalHeight !== 0;
  }

  function start() {

    let checkImageList = []
    var shapesImages = stage.find('Image');
    shapesImages.forEach(element => {
      let htmlImage = element.image()

      if (htmlImage.src) {
        checkImageList.push(htmlImage)
      }

    })


    function checkAllImagesLoaded(imageList: any[]): boolean {
      return imageList.every(img => imgLoaded(img));
    }

    let timer = 0;
    let timerIndex = 0;
    let timerMax = TIMER_LIST.length;

    // you import worker from './worker.js' current directory
    let meta = import.meta.url
    const w = new Worker(new URL('./worker.js', import.meta.url))


    w.onmessage = checkImages

    function checkImages(event) {
      console.log('Message received from worker', event.data)
      timer += TIMER_LIST[timerIndex];
      if (checkAllImagesLoaded(checkImageList)) {
        unblock();
        w.terminate()
        return;
      }
      timerIndex++;
      if (timerIndex >= timerMax) {
        unblock();
        w.terminate()
        return;
      }
      startTimer();
    }
    function startTimer() {
      // todo to be fixed with  webworker settimeout
      // setTimeout(startTimer, TIMER_LIST[timerIndex]);
      w.postMessage({
        cmd: 'start',
        timer:  TIMER_LIST[timerIndex],
      })
    }

    startTimer();




    let promiseText = []
    var shapesText = stage.find('Text');

    shapesText.forEach(element => {


      //only font for photowish template
      if (element.attrs.pw == true) {
        const url = 'https://fonts.googleapis.com/css2?family=' + element.fontFamily() // + '&display=swap'
        const encoded = encodeURI(url)
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = encoded
        document.head.appendChild(link)
        // load font
        let fontFaceObserver = new FontFaceObserver(element.fontFamily())
        const p = fontFaceObserver.load(null, 3000)
        promiseText.push(p)
      }

    })


    return Promise.all(promiseText)
      .then((a) => {
        console.log('all text loaded')
      })
      .catch((e) => {
        console.log('error loading text')
      })
      .finally(() => {
        setTimeout(() => {
          stage.batchDraw()
        }, 10);
        // unblock()
      })

  }
}
