import { defineStore } from 'pinia'

import { useEventStore } from './event'
import { usePreweddingStore } from './prewedding'
import { useSettingControlStore } from './settingControl'
import { useTemplateStore } from './template'
import { useWishStore } from './wish'

import { useWindowSize } from '@vueuse/core'

import delay from '~/utils/delay'

import _forEach from 'lodash/filter.js'
import _first from 'lodash/first.js'
import _size from 'lodash/size.js'

export const useSlideStore = defineStore({
  id: 'slide',
  state: () => {
    return {
      tmpMode: '',
      //
      stage: null as any,
      isRender: true,
      view: 'prewedding', // prewedding, wish
      playMode: '', // mix, wish
      index: -1,  // init start with -1
      limit: 4,  // limit play instace per set
      second: 0,
      percent: 0,
      wishInstance: {} as any,
      showNewWishIcon : false,
      preweddingInstance: null as any,

      //iconDisplay: 'prewedding', // prewedding, wish, new_wish  

      previousView: {
        view: null,
        index: null,
        active: false
      }, // prewedding, wish

      allInstancesPlayed: 0,
      preweddingInstancesPlayed: 0,
      wishInstancesPlayed: 0,

      settimeOutInstance: null,
      setIntervalPercent: null,

      timerMs: 0,
      INTERVAL: 1000,

      watchIndexId: null,
      transitionWish: true,
      transitionOpacity: 1,

      myWorker: undefined,
    }
  },
  actions: {

    initWebWorkerMessage() {
      const self = this;

      if(!self.myWorker || self.myWorker === 'undefined') {
        self.myWorker = new Worker('/js/worker.js');
      }

      // Receive messages from the worker
      self.myWorker.onmessage = function (e) {
        const message = e.data
        console.log(message);
        self.main()
      }
    },

    main() {
      const wishStore = useWishStore()
      this.index++
      this.watchIndexAndViewMode();
      this.restore_Previous_View_And_Save_If_New_Wish_Avaliable(wishStore);

      let newWishList = wishStore.getNewDocIdList()
      let currentView = newWishList.length > 0 ? 'new_wish' : this.view

      switch (currentView) {
        case 'prewedding':
          this.renderPrewedding();
          break;
        case 'wish':
          this.renderWish();
          break;
        case 'new_wish':
          this.view = 'wish'
          this.showNewWishIcon = true
          this.renderWish(newWishList[0]);
          wishStore.newWishes.shift();
          break;
        default:
          break;
      }
      this.startTimer()

      // worker clear timer and run
      if (window.Worker) {
        this.myWorker && this.myWorker.postMessage({
          company: 'photowish',
          timer: this.itemTimer * 1000,
        });
        
      } else {
        this.settimeOutInstance = setTimeout(() => {
          this.main()
        }, (this.itemTimer * 1000 )+20)
      }

      this.allInstancesPlayed++
    },
    restore_Previous_View_And_Save_If_New_Wish_Avaliable(wishStore) {

      // restore previous view
      if (this.previousView.active) {
        this.view = this.previousView.view
        this.index = this.previousView.index
        this.previousView.active = false
      }


      // save previous view if new wish available and not yet save
      let newWishList = wishStore.getNewDocIdList()
      if (newWishList.length > 0 && !this.previousView.active) {
        this.previousView.view = this.view
        this.previousView.index = this.index
        this.previousView.active = true
      }

    },
    async renderWish(forceDocId = null) {
      const wishStore = useWishStore()
      const templateStore = useTemplateStore()

      if(wishStore.all.length == 0) {
        this.view = 'prewedding'
        this.renderPrewedding();
        return
      }


      if (wishStore.queue.length == 0) {
        let previousId = this.wishInstance.docId
        wishStore.shuffle(previousId)
      }


      // ---------------- set limit  เมื่อมีน้อยกว่า 4---------
      if (wishStore.all.length < this.limit) {
        this.limit = wishStore.all.length
      }

      // ------------------------------------

      let docId = forceDocId || wishStore.queue.shift()
      if (docId == undefined) {
        return
      }

      let current = wishStore.getWishDataById(docId)
      // not found wish maybe user delete wish
      if (!current) {
        this.next()
        return
      }
      console.log("wishInstancesPlayed", this.wishInstancesPlayed, current)
      this.wishInstance = current
      
      if (this.stage && this.stage.destroy) {
        this.stage.width(0)
        this.stage.height(0)
        console.log("destroy stage", this.wishInstancesPlayed)
        this.stage.batchDraw()
        this.stage.destroy()
        this.stage = null
        this.transitionWish = false
        this.transitionOpacity = 0
      }

      try {
        templateStore.docId = docId
        const renderStage = await templateStore.render(current)
        templateStore.docId = docId

        // -- transition  animation --
        this.transitionWish = true
        this.transitionOpacity = 1
        // ---------------------------

        this.stage = renderStage
        this.wishInstancesPlayed++

        wishStore.updatePlayWish(current) // <== database

      } catch (error) {
        console.error(error)
      }

    },
    async renderPrewedding() {
      const preweddingStore = usePreweddingStore()
    //  this.wishInstance = null
      this.stage && this.stage.destroy()
      this.stage = null

      const tmpStage = this.stage


      if (tmpStage) {
        tmpStage.destroy()
        this.stage = null
      }

      if (preweddingStore.queue.length == 0) {
        let previousUrl = this.preweddingInstance
        preweddingStore.shuffle(previousUrl)
      }

      this.preweddingInstance = preweddingStore.queue.shift()
      this.preweddingInstancesPlayed++
    },

    watchIndexAndViewMode() {

      // toggle view mode 
      let newVal = this.index

      if (this.view == 'prewedding' && newVal >= this.limit && this.playMode == 'mix') {
        this.view = 'wish'
        this.index = 0
      } else if (this.view == 'wish' && newVal >= this.limit && this.playMode == 'mix') {
        this.view = 'prewedding'
        this.index = 0

      }

      this.showNewWishIcon = false
      if (this.playMode == 'wish') {
        this.view = 'wish'
        if (newVal >= this.limit) {
          this.index = 0
        }
      }

      this.limit = this.itemLimit
    },

    //---
    async startTimer() {
      // clear prevoius  
      // this.settimeOutInstance = clearTimeout(this.settimeOutInstance)
      this.setIntervalPercent = clearInterval(this.setIntervalPercent)

      this.percent = 0
      this.timerMs = 0
      const durationSec = this.itemTimer

      //percent  

      this.setIntervalPercent = setInterval(() => {

        this.timerMs = this.timerMs + this.INTERVAL

        this.percent = (this.timerMs / (durationSec * 1000)) * 100
        if (this.percent >= 100) {
          this.percent = 100
        }
      }, this.INTERVAL)


    },



     

    rerender() {
      this.isRender = false
      setTimeout(() => {
        this.isRender = true
      }, 800)
    },

    next() {
      this.main()
    },

  },
  getters: {
    itemLimit(state) {
      const settingControl = useSettingControlStore()
      const timer: any = settingControl.timer
      let view = state.view
      
      return timer[view].limit
    },
    itemTimer(state) {
      const settingControl = useSettingControlStore()
    
      const timer: any = settingControl.timer
      console.log(timer)
      const view = state.view
      return timer[view].timer
    },

  }
})
