<template>
  <div>
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
    <div class="hidden" id="prefetch-element"></div>
  </div>
</template>

<script lang="ts" setup>
  import { useAppStateStore } from '~/store/appState'

  const  $router = useRouter()
  const  $route = useRoute()
  let appStateStore = useAppStateStore()

  onBeforeMount(async () => {
    const eventIdParam = $route.params.eventId
    const eventIdQuery = $route.query.q ||  $route.query.eventId 
    const eventIdLocalStorage = window.localStorage.getItem('eventId')

    if(eventIdParam) {
      console.log('param --->', eventIdParam)
      window.localStorage.setItem('eventId', eventIdParam)
      return await appStateStore.init(eventIdParam)
    }

    if(eventIdQuery) {
      console.log('query --->', eventIdQuery)
      window.localStorage.setItem('eventId', eventIdQuery)
      return await appStateStore.init(eventIdQuery)
    }

    if(eventIdLocalStorage) {
      console.log('localstorage --->', eventIdLocalStorage)
      return await appStateStore.init(eventIdLocalStorage)
    }


    return $router.push('/')
  })
</script>

<style>
</style>
