import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'

import { onSnapshot, doc, getDoc } from 'firebase/firestore'

import { Event } from '~/interfaces/database/event'

import _forEach from 'lodash/forEach.js'
import _cloneDeep from 'lodash/cloneDeep.js'
import _capitalize from 'lodash/capitalize.js'
import dayjs from 'dayjs'
import { useBrowserLocation } from '@vueuse/core'

import { useTemplateStore } from './template'


export const useEventStore = defineStore({
  id: 'event',
  state: () => {
    return {
      eventId: null,
      package: 'free',
      event: {},
    }
  },
  actions: {
    async main(eventId: string) {
      await this.realtimeFetchEvent(eventId)
    },
    realtimeFetchEvent(eventId: string) {
      const p = new Promise(async resolve => {
        const $router = useRouter()
        const templateStore = useTemplateStore()

        const docRef = doc(db, 'events', eventId)
      
        onSnapshot(docRef, (doc) => {
          if (!doc.exists()) {
            this.eventId = null
            window.localStorage.removeItem('eventId')
            return $router.push('/')
          }

          const data: any = Object.assign({}, doc.data(), {
            docId: doc.id
          })

          this.eventId = data.eventId
          this.package = data.package
          this.event = data
          templateStore.templateUsedIds = Object.keys(data.templates) || []
          
          window.localStorage.setItem('eventId', eventId)
          resolve(true)
        })
      })

      return p
    },

    async checkEvent(targetEventId: string) {
      if(!targetEventId) {
        return null
      }
      const docRef = doc(db, 'events', targetEventId)
      const response = await getDoc(docRef)

      if(response.exists()) {
        console.log('data =>', response.data())
        return true
      } else {
        console.log(`eventId: ${targetEventId} is not found`)
        return false
      }

    }
  },
  getters: {
    information(state) {
      return state.event?.information || null
    },
    slideMode(state) {
      return state.event?.settings?.slideMode || 'mix'
    },
    brideName(state) {
      return  _capitalize(state.information?.bride?.firstName) || ''
    },
    groomName(state) {
      return _capitalize(state.information?.groom?.firstName) || ''
    },
    eventTitle(state) {
      if(this.event?.information?.customTitle) {
        return this.event?.information?.customTitle
      }

      if(this.event?.information?.title) {
        return this.event?.information?.title
      }
      return `${this.brideName} & ${this.groomName} 's Wedding Day`
    },
    eventType(state) {
      return state.event.eventType || 'wedding'
    },
    shareLinkUrl(state) {
      const location = useBrowserLocation()
      const url = `${location.value.origin}/?q=${state.eventId}`
      return url
    },
    shareLinkGuestUrl(state) {
      const location = useBrowserLocation()
      const url = `https://photowish-wish-images.web.app/?eventId=${state.eventId}`
      return url
    },
  }
})