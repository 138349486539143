import { initializeApp, getApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyDJjlrMUcTHETMahGo7-iprTGopbs88ZOw",
  authDomain: "photowishv2.firebaseapp.com",
  databaseURL: "https://photowishv2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "photowishv2",
  storageBucket: "photowishv2.appspot.com",
  messagingSenderId: "150081644918",
  appId: "1:150081644918:web:d72be03045f05cdacbb257",
  measurementId: "G-W1LJ23FB7M"
};

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)
const storage = getStorage(app)
const realtimeDatabase = getDatabase(app)
const firebase = getApp()


export {
  app,
  db,
  storage,
  firebase,
  realtimeDatabase
}
